import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByCampaign = async (id) => {
  try {
    const response = await axios.get(`/campaigns/${id}/cores`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les carottes" + getMessage(error.response.status)
    );
    throw error;
  }
};

const getPdf = async (id) => {
  try {
    const response = await axios.get(`/cores/${id}/pdf`, {
      responseType: "arraybuffer",
    });
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer le pdf" + getMessage(error.response.status)
    );
    throw error;
  }
};

const create = async (core) => {
  try {
    const response = await axios.post(`/cores`, core);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de créer la carotte" + getMessage(error.response.status)
    );
    throw error;
  }
};

const edit = async (id, core) => {
  try {
    const response = await axios.put(`/cores/${id}`, core);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier la carotte" + getMessage(error.response.status)
    );
    throw error;
  }
};

const cancel = async (id, note = '') => {
  try {
    axios.delete(`/cores/${id}`, {note});
  } catch (error) {
    toast(
      "error",
      "Impossible de supprimer la carotte" + getMessage(error.response.status)
    );
    throw error;
  }
}

export default {
  findByCampaign,
  getPdf,
  create,
  edit,
  cancel
};
