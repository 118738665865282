<template>
  <div class="leftView-container">
    <!-- <div class="logo"></div> -->
    <div class="movie">
      <video class="presentationMovie" controls>
        <source src="../../assets/videos/roadMaorie.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.leftView-container {
  height: 100vh;
  width: 75vw;
  display: flex;
  align-items: center;
  background-color: #2c0703;
  justify-content: center;

  // .logo {
  //   background-image: url(../assets/images/logo.png);
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   background-position: center;
  //   width: 70%;
  //   height: 200px;
  // }
  .movie {
    display: flex;
    justify-content: center;
    .presentationMovie {
      width: 90%;
      height: auto;
    }
  }
}
</style>
