import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByCampaign = async (id) => {
  try {
    const response = await axios.get(`/campaigns/${id}/map-layers`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les couches" + getMessage(error.response.status)
    );
    throw error;
  }
};

const getLegends = async (id) => {
  try {
    const response = await axios.get(`/map-layers/${id}/value-classes`, {showLoader:false});
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les légendes" + getMessage(error.response.status)
    );
    throw error;
  }
};

const getImageSettings = async (layerId) => {
  try {
    const response = await axios.get(
      "/map-layers/" + layerId + "/imageSettings"
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les données" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  findByCampaign,
  getLegends,
  getImageSettings,
};
