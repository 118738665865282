<template>
<div id="guestLayout">
  <router-view></router-view>
</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
#guestLayout {
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
}

</style>