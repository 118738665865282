import Vue from "vue";
import VueRouter from "vue-router";
import DisconnectedLayout from "../layouts/Disconnected.vue";
import ConnectedLayout from "../layouts/Connected.vue";
import GuestLayout from "../layouts/Guest.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: DisconnectedLayout,
    children: [
      {
        path: "/",
        name: "login",
        meta: {
          title: "Connexion",
        },
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/views/authentication/Login.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: DisconnectedLayout,
    children: [
      {
        path: "/password-edit",
        name: "edit-password",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/views/authentication/PasswordEdit.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: DisconnectedLayout,
    children: [
      {
        path: "/download",
        name: "download-zip",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/views/files/DownloadLink.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    component: DisconnectedLayout,
    children: [
      {
        path: "/share-link",
        name: "share-link-auth",
        meta: {
          title: "Connexion par token",
        },
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/views/authentication/ShareLinkAuth.vue"
          ),
      },
    ],
  },
  {
    path: "guests",
    alias: "/guests",
    name: "guestLayout",
    component: GuestLayout,
    children: [
      {
        path: "coring-campaign/:id/dashboard",
        alias: "/guests/coring-campaign/:id/dashboard",
        meta: {
          title: "Dashboard campagne de carottage",
        },
        name: "coring-campaign-dashboard-guest",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/coring/CoringCampaignDashboard.vue"
          ),
      },
      {
        path: "coring-campaign/:id/map",
        alias: "/guests/coring-campaign/:id/map",
        meta: {
          title: "Carte campagne de carottage",
        },
        name: "coring-campaign-map-guest",
        component: () =>
          import(
            /* webpackChunkName: "coringCampaignMap" */ "@/views/coring/CoringCampaign.map.vue"
          ),
      },
      {
        path: "auscultation-campaign/:id/map",
        alias: "/guests/auscultation-campaign/:id/map",
        meta: {
          title: "Carte campagne d'auscultation",
        },
        name: "auscultation-campaign-map-guest",
        component: () =>
          import(
            /* webpackChunkName: "AuscultationCampaign" */ "@/views/auscultation/Auscultation.map.vue"
          ),
      },
      {
        path: "auscultation-campaign/:id/dashboard",
        alias: "/guests/auscultation-campaign/:id/dashboard",
        meta: {
          title: "Dashboard campagne d'auscultation",
        },
        name: "auscultation-campaign-dashboard-guest",
        component: () =>
          import(
            /* webpackChunkName: "AuscultationCampaign" */ "@/views/auscultation/Auscultation.dashboard.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "disconnected",
    component: DisconnectedLayout,
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/views/authentication/Login.vue"
          ),
      },
    ],
  },
  {
    path: "/",
    name: "connected",
    component: ConnectedLayout,
    children: [
      {
        path: "/workspaces/:archived?",
        name: "workspaces",
        meta: {
          title: "Workspaces",
        },
        component: () =>
          import(/* webpackChunkName: "workspaces" */ "@/views/Workspaces.vue"),
      },
      {
        path: "/project/:id",
        meta: {
          title: "Mes projets",
        },
        name: "project",
        component: () =>
          import(
            /* webpackChunkName: "campaign" */ "@/views/campaign/Campaign.vue"
          ),
      },
      {
        path: "/coring-campaign/:id",
        name: "coring-campaign",
        meta: {
          title: "Campagne de carottage",
        },
        component: () =>
          import(
            /* webpackChunkName: "coringCampaign" */ "@/views/coring/CoringCampaign.vue"
          ),
      },
      {
        path: "/profile",
        meta: {
          title: "Mon profil",
        },
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
      },
      {
        path: "/campaign/:id/files",
        meta: {
          title: "Fichiers",
        },
        name: "campaign-files",
        component: () =>
          import(
            /* webpackChunkName: "campaignFiles" */ "@/views/files/CampaignFiles.vue"
          ),
      },
      {
        path: "/campaign/:id/deliverables",
        meta: {
          title: "Livrables",
        },
        name: "campaign-deliverables",
        component: () =>
          import(
            /* webpackChunkName: "campaignDeliverables" */ "@/views/files/CampaignDeliverables.vue"
          ),
      },
      {
        path: "/campaign/:id/manage",
        meta: {
          title: "Gestion de campagne",
        },
        name: "manage-campaign",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/campaign/UpdateCampaign.vue"
          ),
      },
      {
        path: "/coring-campaign/:id/dashboard",
        meta: {
          title: "Dashboard campagne de carottage",
        },
        name: "coring-campaign-dashboard",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/coring/CoringCampaignDashboard.vue"
          ),
      },
      {
        path: "/coring-campaign/:id/map",
        meta: {
          title: "Carte campagne de carottage",
        },
        name: "coring-campaign-map",
        component: () =>
          import(
            /* webpackChunkName: "coringCampaignMap" */ "@/views/coring/CoringCampaign.map.vue"
          ),
      },
      {
        path: "/auscultation-campaign/:id/map",
        meta: {
          title: "Carte campagne d'auscultation",
        },
        name: "auscultation-campaign-map",
        component: () =>
          import(
            /* webpackChunkName: "AuscultationCampaign" */ "@/views/auscultation/Auscultation.map.vue"
          ),
      },
      {
        path: "/auscultation-campaign/:id/dashboard",
        meta: {
          title: "Dashboard campagne d'auscultation",
        },
        name: "auscultation-campaign-dashboard",
        component: () =>
          import(
            /* webpackChunkName: "AuscultationCampaign" */ "@/views/auscultation/Auscultation.dashboard.vue"
          ),
      },
      {
        path: "/legal-statement",
        name: "legal-statement",
        component: () =>
          import(
            /* webpackChunkName: "campaignFiles" */ "@/views/LegalStatement.vue"
          ),
      },
      {
        path: "/admin/organizations",
        meta: {
          title: "Administration des organisations",
        },
        name: "organizations-administration",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationsAdministration" */ "@/views/administration/OrganizationsAdministration.vue"
          ),
      },
      {
        path: "/admin/organizations/:id",
        meta: {
          title: "Administration d'une organisation",
        },
        name: "organization-administration",
        component: () =>
          import(
            /* webpackChunkName: "OrganizationAdministration" */ "@/views/administration/OrganizationAdministration.vue"
          ),
      },
      {
        path: "/admin/campaigns/:id",
        meta: {
          title: "Administration d'une campagne",
        },
        name: "campaign-administration",
        component: () =>
          import(
            /* webpackChunkName: "CampaignAdministration" */ "@/views/administration/CampaignAdministration.vue"
          ),
      },
      {
        path: "*",
        meta: {
          title: "Page non trouvée",
        },
        name: "page-not-found",
        component: () =>
          import(
            /* webpackChunkName: "CampaignAdministration" */ "@/views/PageNotFound.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title
      ? toRoute.meta.title + " - MAORIE"
      : "MAORIE";

  next();
});

export default router;
