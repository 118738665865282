import authorizations from "./authorizations.json";

/**
 *
 * @param {string} jobName The user's job
 * @param {array} jobsList The list of jobs from the authorization
 * @returns true if jobName is in jobsList
 */
const isJobInList = (jobName, jobsList) => {
  let res = false;
  jobsList.forEach(job => {
    if (job === jobName) res = true;
  });

  return res;
};

/**
 *
 * @param {array} roles The user's roles
 * @param {array} rolesList The list of roles from the authorization
 * @returns true if at least one role is in rolesList
 */
const isAtLeastOneRoleInList = (roles, rolesList) => {
  let res = false;
  rolesList.forEach(role => {
    if (roles.includes(role)) res = true;
  });

  return res;
};

/**
 *
 * @param {object} user the user data with informations about user rights. must have an object property userCampaignData
 * @param {array} userCampaignParams array of needed params to have authorization
 * @returns
 */
const hasUserCampaignAuthorization = (user, userCampaignParams) => {
  let result = true;
  if (0 === userCampaignParams.length) return result;

  userCampaignParams.forEach(campaignParam => {
    if (isAtLeastOneRoleInList(user.roles, campaignParam.ifRoles))
      result =
        user.userCampaignData[campaignParam.property] === campaignParam.value
          ? result
          : false;
  });

  return result;
};

const rolesHierarchy = {
  ROLE_READ_ONLY: [],
  ROLE_USER: ["ROLE_READ_ONLY"],
  ROLE_CUSTOMER_USER: ["ROLE_USER"],
  ROLE_CUSTOMER_MAIN: ["ROLE_CUSTOMER_USER", "ROLE_USER"],
  ROLE_CUSTOMER_SUPERVISOR: ["ROLE_CUSTOMER_MAIN", "ROLE_CUSTOMER_USER", "ROLE_USER"],
  ROLE_NEXTROAD_USER: ["ROLE_USER", "ROLE_CUSTOMER_USER", "ROLE_CUSTOMER_MAIN"],
  ROLE_NEXTROAD_MAIN: ["ROLE_NEXTROAD_USER", "ROLE_USER", "ROLE_CUSTOMER_USER", "ROLE_CUSTOMER_MAIN"],
  ROLE_NEXTROAD_ADMIN: ["ROLE_NEXTROAD_MAIN", "ROLE_NEXTROAD_USER", "ROLE_USER", "ROLE_CUSTOMER_USER", "ROLE_CUSTOMER_MAIN"],
  ROLE_ADMIN: ["ROLE_CUSTOMER_MAIN", "ROLE_NEXTROAD_ADMIN", "ROLE_NEXTROAD_MAIN", "ROLE_NEXTROAD_USER", "ROLE_USER", "ROLE_CUSTOMER_SUPERVISOR", "ROLE_CUSTOMER_USER"],
  ROLE_SUPER_ADMIN: ["ROLE_ADMIN", "ROLE_CUSTOMER_MAIN", "ROLE_NEXTROAD_ADMIN", "ROLE_NEXTROAD_MAIN", "ROLE_NEXTROAD_USER", "ROLE_USER", "ROLE_CUSTOMER_SUPERVISOR", "ROLE_CUSTOMER_USER"]
};

export default {
  authorizations,
  /**
   * Check if user has right on provided authorization
   * @param {object} user current user's object. must have an object property userCampaignData
   * @param {object} authorization authorization to check
   * @returns true if user has right
   */
  hasRight(user, authorization) {
    let hasRight = false;
    if (!user.roles) return hasRight;

    hasRight = isJobInList(user.job, authorization.jobs) ? true : hasRight;

    hasRight = isAtLeastOneRoleInList(user.roles, authorization.roles)
      ? true
      : hasRight;

    if (0 === authorization.roles.length && 0 === authorization.jobs.length)
      hasRight = true;

    hasRight = hasUserCampaignAuthorization(
      user,
      authorization.userCampaignParams
    )
      ? hasRight
      : false;

    return hasRight;
  },
  /** Returns true if role is higher hierarchy than all roles in userRoles */
  isRoleAbove(userRoles, role) {
    let higherRole = "ROLE_USER";
    userRoles.forEach(role => {
      if (rolesHierarchy[role].includes(higherRole)) higherRole = role;
    });

    return rolesHierarchy[role].includes(higherRole);
  }
};
