import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findBySettingsAndId = async (settingId, id) => {
  try {
    const response = await axios.get(`/image-settings/${settingId}/imageList/${id}`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les photos" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  findBySettingsAndId,
};
