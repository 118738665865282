<template>
  <v-btn
    class="MediumButtonSlot"
    @click="clicked($event)"
    :to="_to"
    :disabled="_disabled"
    :type="_type"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "MediumButtonSlot",
  props: ["_to", "_disabled", "_type"],
  methods: {
    clicked($event) {
      this.$emit("click", $event);
    }
  }
};
</script>

<style lang="scss" scoped>
.MediumButtonSlot {
  box-shadow: none;
  background-color: grey;
}
</style>
