<template>
  <v-btn
    color="#2c0703"
    class="LowButtonSlot"
    text
    @click="clicked($event)"
    :to="_to"
    :disabled="_disabled"
    :small="_small"
  >
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  name: "LowButtonSlot",
  props: ["_to", "_disabled", "_small"],
  methods: {
    clicked($event) {
      this.$emit("click", $event);
    }
  }
};
</script>
<style lang="scss" scoped>
.LowButtonSlot {
}
</style>
