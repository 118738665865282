import Vue from "vue";

const rules = {
  required: (value) => {
    if (!value) return "Ce champ est requis";
    else {
      return !!value || "Ce champ est requis";
    }
  },
  requiredBoolean: (value) => {
    return (value != undefined && value != null) || "Ce champ est requis";
  },
  validEmail: (value) => {
    if (!value) return "Veuillez saisir une adresse mail valide";
    else {
      if (value.length > 0) {
        const pattern =
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return pattern.test(value) || "Veuillez saisir une adresse mail valide";
      } else {
        return "Veuillez saisir une adresse mail valide";
      }
    }
  },
  isPasswordValidFormat: (value) => {
    if (!value)
      return "Min. 8 caractères avec au moins une lettre majuscule, un chiffre et un caractère spécial.";
    else {
      if (value.length > 0) {
        const pattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 caractères avec au moins une lettre majuscule, un chiffre et un caractère spécial."
        );
      }
    }
  },
  minChars: (value) => {
    if (!value) return "3 caractères minimum";
    else {
      return value.length > 2 || "3 caractères minimum";
    }
  },
  atLeastOneRole: (value) => {
    if (!value) return "Veuillez attribuer au moins un rôle";
    else {
      return value.length > 0 || "Veuillez attribuer au moins un rôle";
    }
  },

  atLeastOneTechnician: (value) => {
    if (!value) return "Au moins 1 technicien requis";
    else {
      return value.length > 0 || "Au moins 1 technicien requis";
    }
  },

  atLeastOneMaterial: (value) => {
    if (!value) return "Renseignez une catégorie de matériaux";
    else {
      return undefined !== value || "Renseignez une catégorie de matériaux";
    }
  },

  requiredRoad: (value) => {
    return !!value || "Renseignez la route";
  },

  correctYearFormat: (value) => {
    if (!value || value === "") return true;

    const regex = new RegExp("(^[0-9]{4}$)");
    return regex.test(value) || "format de l'année invalide";
  },

  onlyNumbers: (value) => {
    if ("" === value || value == undefined) return true;
    const regex = new RegExp("(^[0-9]*$)");
    return regex.test(value) || "Seulement des chiffres";
  },

  FourMax: (value) => {
    if ("" === value || value == undefined) return true;
    value = value.toString();
    return value.length <= 4 || "4 chiffres maximum";
  },

  requiredDiameter: (value) => {
    return !!value || "Renseignez le diamètre de la carotte";
  },

  layerState: (value) => {
    return !!value || `Indiquez l'état de la couche`;
  },

  interfaceState: (value) => {
    return !!value || `Indiquez l'état de l'interface`;
  },

  min5Chars: (value) => {
    if (!value) return "Le nom doit comporter au moins 5 caractères";
    else {
      return (
        (value && value.length >= 5) ||
        "Le nom doit comporter au moins 5 caractères"
      );
    }
  },

  min6Chars: (value) => {
    if (!value) return "Le nom doit comporter au moins 6 caractères";
    else {
      return (
        (value && value.length >= 6) ||
        "Le nom doit comporter au moins 6 caractères"
      );
    }
  },

  min8Chars: (value) => {
    if (!value) return "Le nom doit comporter au moins 8 caractères";
    else {
      return (
        (value && value.length >= 8) ||
        "Le nom doit comporter au moins 8 caractères"
      );
    }
  },

  min30Chars: (value) => {
    if (!value) return "La description doit comporter au moins 30 caractères";
    else {
      return (
        (value && value.length >= 30) ||
        "La description doit comporter au moins 30 caractères"
      );
    }
  },

  validZipCode: (value) => {
    if (!value) return "Code postal invalide";
    else {
      return value.length === 5 || "Code postal invalide";
    }
  },

  atLeastOne: (value) =>
    !value ? "Au moins 1 requis" : value.length > 0 || "Au moins 1 requis",
};

export default {
  install() {
    Vue.prototype.$rules = rules;
  },

  rules,
};
