import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

/**
 * Upload picture to S3 and save object to DB, s3Path will be automatically overwritten
 * @param {FormData} formData files informations and payload
 * @param {object} document infos to store about the document entity
 */
const upload = async (formData, corePic) => {
  try {
    let response = await axios.post("/core-pictures/upload", formData);
    corePic.path = response.data.path;
    if (corePic.id) corePic = await update(corePic.id, { ...corePic });
    else corePic = await create({ ...corePic });
    toast("success", "Document ajouté !");

    return corePic;
  } catch (error) {
    toast(
      "error",
      "Impossible d'uploader la photo" + getMessage(error.response.status)
    );
    throw error;
  }
};

const create = async (corePicData) => {
  try {
    const response = await axios.post(`/core-pictures`, corePicData);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de créer la photo" + getMessage(error.response.status)
    );
    throw error;
  }
};

const update = async (corePictureId, corePicData) => {
  try {
    const response = await axios.put(
      `/core-pictures/${corePictureId}`,
      corePicData
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier la photo" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  upload,
  create,
  update,
};
