import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const getAvailableStatuses = async (campaignType) => {
  try {
    const response = await axios.get(`/campaign-statuses?type=${campaignType}`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les statuts de campagne" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const getData = async (id, format, type = "") => {
  try {
    const response = await axios.get(
      `/campaigns/${id}/data?format=${format}&type=${type}`
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les données HAP" +
        getMessage(error.response.status)
    );
    throw error;
  }
};
const getPrestations = async (id) => {
  try {
    const response = await axios.get(`/campaigns/${id}/prestations`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les prestations" +
        getMessage(error.response.status)
    );
    throw error;
  }
};
const editPrestations = async (id, prestations) => {
  try {
    const response = await axios.put(`/campaigns/${id}/prestations`, {
      prestations,
    });

    toast("success", "Prestations modifiées !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier les prestations" +
        getMessage(error.response.status)
    );
    throw error;
  }
};
const getDocumentsStats = async (id) => {
  try {
    const response = await axios.get(`/campaigns/${id}/documents-stats`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les statistiques pour le compte des documents"
    );
    throw error;
  }
};
const findAll = async () => {
  try {
    const response = await axios.get("/campaigns");
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les campagnes" +
        getMessage(error.response.status)
    );
    throw error;
  }
};
const findById = async (id) => {
  try {
    const response = await axios.get(`/campaigns/${id}`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer la campagne" + getMessage(error.response.status)
    );
    throw error;
  }
};
const findByWorkspace = async (workspaceId, params) => {
  try {
    const response = await axios.get(`/workspaces/${workspaceId}/campaigns`, {
      params,
    });
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les campagnes" +
        getMessage(error.response.status)
    );
    throw error;
  }
};
const findByUser = async (userId, admin = null) => {
  try {
    let route = `/users/${userId}/campaigns`;
    if (true === admin) route += "?admin=true";
    const response = await axios.get(route);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les campagnes" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const editComment = async (campaignId, globalComment) => {
  try {
    const response = await axios.patch(`/campaigns/${campaignId}`, {
      globalComment,
    });

    toast("success", "Commentaire modifié !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier le commentaire" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const addMember = async (campaignId, userId, userCampaignParams) => {
  try {
    const response = await axios.post(
      `/campaigns/${campaignId}/users/${userId}`,
      userCampaignParams
    );

    toast("success", "Utilisateur ajouté !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible d'ajouter l'utilisateur" + getMessage(error.response.status)
    );
    throw error;
  }
};

const editMemberShip = async (campaignId, userId, memberShip) => {
  try {
    const response = await axios.put(
      `/campaigns/${campaignId}/users/${userId}`,
      memberShip
    );

    toast("success", "Droits modifiés !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier les droits de l'utilisateur" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const removeMember = async (campaignId, userId) => {
  try {
    const response = await axios.delete(
      `/campaigns/${campaignId}/users/${userId}`
    );

    toast("success", "Utilisateur retiré !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de retirer l'utilisateur" + getMessage(error.response.status)
    );
    throw error;
  }
};

const getUserCampaigns = async (campaignId, teamMember = null) => {
  try {
    let params = { query: {} };
    if (null !== teamMember && undefined !== teamMember)
      params.query.teamMember = teamMember;
    const response = await axios.get(
      `/campaigns/${campaignId}/user-campaigns`,
      { params }
    );

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les membres" + getMessage(error.response.status)
    );
    throw error;
  }
};

const editUsers = async (id, users) => {
  try {
    const response = await axios.put(`/campaigns/${id}/users`, users);

    toast("success", "Utilisateurs modifiés !");
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier les utilisateurs" +
        getMessage(error.response.status)
    );
    throw error;
  }
};
const inviteGuestUser = async (campaignId) => {
  try {
    const response = await axios.post(`/campaigns/${campaignId}/share`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de partager la campagne" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  getAvailableStatuses,
  getData,
  getPrestations,
  editPrestations,
  getDocumentsStats,

  findAll,
  findById,
  findByWorkspace,
  findByUser,

  addMember,
  editMemberShip,
  removeMember,
  getUserCampaigns,

  editComment,

  editUsers,
  inviteGuestUser,
};
