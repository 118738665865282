import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findById = async (id) => {
  try {
    const response = await axios.get(`/auscultation-campaigns/${id}`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer la campagne" + getMessage(error.response.status)
    );
    throw error;
  }
};

const create = async (campaign) => {
  try {
    const response = await axios.post("/auscultation-campaigns", campaign);

    toast("success", "Campagne créée !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de créer la campagne" + getMessage(error.response.status)
    );
    throw error;
  }
};

const edit = async (id, campaign) => {
  try {
    const response = await axios.put(`/auscultation-campaigns/${id}`, campaign);

    toast("success", "Campagne modifiée !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier la campagne" + getMessage(error.response.status)
    );
    throw error;
  }
};

const editStatus = async (campaignId, status) => {
  try {
    const response = await axios.patch(
      `/auscultation-campaigns/${campaignId}`,
      { status }
    );

    toast("success", "Statut modifié !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier le statut" + getMessage(error.response.status)
    );
    throw error;
  }
};

const getChartData = async (id) => {
  try {
    const response = await axios.get(
      `/auscultation-campaigns/${id}/chart-data`
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les données de la campagne" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const getStatisticTables = async (id, category) => {
  try {
    const response = await axios.get(
      `/auscultation-campaigns/${id}/statistic-tables` + (null !== category ? '/'+category:'')
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les tableaux des statistiques de la campagne" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const getStatisticTableData = async (id, filters) => {
  let queryFilters = new URLSearchParams(filters);
  try {
    const response = await axios.get(      
      `/statistic-tables/${id}/generate-content?`+queryFilters.toString()
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les tableaux des statistiques de la campagne" +
        getMessage(error.response.status)
    );
    throw error;
  }  
};

export default {
  findById,
  create,
  edit,
  editStatus,
  getChartData,
  getStatisticTables,
  getStatisticTableData,
};
