<template>
  <div id="connected">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini"
      app
      left
      class="m_navigation"
      permanent
    >
      <div class="logo-div">
        <v-btn
          icon
          @click.stop="mini = !mini"
          :class="mini ? 'm_expand_icon align-middle' : 'm_retract_icon mr-2'"
        >
          <v-icon color="white">mdi-menu</v-icon>
        </v-btn>
      </div>
      <router-link
        :to="
          $hasRight('globalActions.showHomeBtn') ? { name: 'workspaces' } : ''
        "
        class="text-decoration-none m_primary_links"
      >
        <img
          v-if="!mini"
          class="logo"
          src="../assets/images/MAORIE_BLANC-low.png"
        />
      </router-link>

      <v-divider></v-divider>

      <v-list dense v-if="undefined === user.displayName">
        <v-subheader class="white--text font-weight-bold ml-2" v-if="!mini"
          >PROJETS</v-subheader
        >
        <v-list-item
          v-for="item in projectItems"
          :key="item.title"
          :to="item.to"
          v-on="item.click ? { click: item.click } : null"
          link
          class="m_primary_links"
          :exact-active-class="
            mini ? 'active-class-list-mini' : 'active-class-list'
          "
        >
          <v-tooltip v-if="mini" right nudge-right="20">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon class="mr-4" v-bind="attrs" v-on="on">
                <v-icon
                  :class="!mini ? 'm_primary_icon' : ''"
                  class="white--text"
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
          <v-list-item-icon class="mr-4" v-else>
            <v-icon
              :class="!mini ? 'm_primary_icon' : ''"
              class="white--text"
              >{{ item.icon }}</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dense v-if="displayFolderMenu">
        <v-subheader class="white--text font-weight-bold ml-2" v-if="!mini"
          >DOSSIERS</v-subheader
        >

        <v-list-item
          v-if="$hasRight('files.accessCustomerFiles')"
          link
          class="m_primary_links"
          :to="{
            name: 'campaign-files',
            params: { id: $route.params.id },
          }"
          :exact-active-class="
            mini ? 'active-class-list-mini' : 'active-class-list'
          "
        >
          <v-tooltip v-if="mini" right nudge-right="20">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon
                  :class="!mini ? 'm_primary_icon' : ''"
                  class="white--text"
                  >mdi-file-chart-outline</v-icon
                >
              </v-list-item-icon>
            </template>
            <span>Fichiers partagés</span>
          </v-tooltip>
          <v-list-item-icon v-else>
            <v-icon :class="!mini ? 'm_primary_icon' : ''" class="white--text"
              >mdi-file-chart-outline</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              Fichiers partagés
              <v-chip
                v-if="$store.state.filesNotifications.files > 0"
                class="ml-2"
                small
                >{{ $store.state.filesNotifications.files }}</v-chip
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$hasRight('files.accessDeliverables')"
          link
          class="m_primary_links"
          :to="{
            name: 'campaign-deliverables',
            params: { id: $route.params.id },
          }"
          :exact-active-class="
            mini ? 'active-class-list-mini' : 'active-class-list'
          "
        >
          <v-tooltip v-if="mini" right nudge-right="20">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon
                  :class="!mini ? 'm_primary_icon' : ''"
                  class="white--text"
                  >mdi-file-check-outline</v-icon
                >
              </v-list-item-icon>
            </template>
            <span>Livrables</span>
          </v-tooltip>
          <v-list-item-icon v-else>
            <v-icon :class="!mini ? 'm_primary_icon' : ''" class="white--text"
              >mdi-file-check-outline</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              Livrables
              <v-chip
                v-if="$store.state.filesNotifications.livreries > 0"
                class="ml-2"
                small
                >{{ $store.state.filesNotifications.livreries }}</v-chip
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list dense>
        <v-subheader class="white--text font-weight-bold ml-2" v-if="!mini"
          >AUTRES</v-subheader
        >
        <v-list-item
          v-for="item in itemsPrimary"
          :key="item.title"
          :to="item.to"
          v-on="item.click ? { click: item.click } : null"
          link
          class="m_primary_links"
          :exact-active-class="
            mini ? 'active-class-list-mini' : 'active-class-list'
          "
        >
          <v-tooltip v-if="mini" right nudge-right="20">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon class="mr-4" v-bind="attrs" v-on="on">
                <v-icon
                  :class="!mini ? 'm_primary_icon' : ''"
                  class="white--text"
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
          <v-list-item-icon class="mr-4" v-else>
            <v-icon
              :class="!mini ? 'm_primary_icon' : ''"
              class="white--text"
              >{{ item.icon }}</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-btn
        plain
        class="white--text text-center force-bottom"
        :to="{
          name: 'legal-statement',
        }"
        v-if="!mini"
        >Mentions légales | CGV | CGU</v-btn
      >

      <template v-slot:append>
        <v-expand-transition>
          <div v-show="showProfile">
            <v-divider></v-divider>

            <v-list class="m_profile">
              <v-list-item-group color="primary">
                <v-list-item
                  v-if="$hasRight('globalActions.showAccountEditBtn')"
                >
                  <v-list-item-icon>
                    <v-icon class="white--text">mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="white--text" @click="accountView"
                      >Mon compte</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-icon>
                    <v-icon class="white--text">mdi-power</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="white--text"
                      >Déconnexion</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-expand-transition>

        <v-card-actions class="pa-0 m_profile">
          <v-list-item two-line class="px-2">
            <v-list-item-avatar>
              <v-img :src="imageLink"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="white--text font-weight-bold">{{
                user.firstname
                  ? user.firstname + " " + user.lastname
                  : user.displayName
              }}</v-list-item-title>
              <v-list-item-subtitle class="m_grey">{{
                $t(user.job) || ""
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-spacer></v-spacer>

          <v-btn class="m_icon" icon @click="showProfile = !showProfile">
            <v-icon color="white" class="ml-4">{{
              showProfile ? "mdi-chevron-down" : "mdi-chevron-up"
            }}</v-icon>
          </v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-main>
      <Loader color="white" :size="200" :width="10" />
      <router-view />
    </v-main>
    <support-modal ref="modal"></support-modal>
  </div>
</template>

<script>
import auth from "@/service/auth";
import SupportModal from "../components/SupportModal";

export default {
  components: {
    SupportModal,
  },
  name: "ConnectedLayout",
  data() {
    return {
      user: {},
      drawer: null,
      displayFolderMenu: false,
      displayFolderMenuRoutes: [
        "coring-campaign",
        "campaign-files",
        "campaign-deliverables",
        "coring-campaign-dashboard",
        "coring-campaign-map",
        "auscultation-campaign-dashboard",
        "auscultation-campaign-map",
      ],
      mini: false,
      showProfile: false,
      userOrganization: {
        id: "",
      },
      profilePictureLink: null,
    };
  },
  computed: {
    imageLink() {
      if (null === this.profilePictureLink)
        return (
          "https://eu.ui-avatars.com/api/?background=random&name=" +
          this.user.firstname +
          "+" +
          this.user.lastname
        );
      else return this.profilePictureLink;
    },
    itemsPrimary() {
      let items = [];
      if ({} === this.user) return items;
      if (this.$hasRight("organizations.manageAll")) {
        items.push({
          title: "Paramètres",
          icon: "mdi-cog",
          to: { name: "organizations-administration" },
        });
      } else if (this.$hasRight("organizations.manageOne")) {
        items.push({
          title: "Paramètres",
          icon: "mdi-cog",
          to: {
            name: "organization-administration",
            params: {
              id: this.userOrganization.id,
            },
          },
        });
      }
      if (this.$hasRight("globalActions.showSupportBtn"))
        items.push({
          title: "Support",
          icon: "mdi-help",
          click: this.showModal,
        });
      return items;
    },
    projectItems() {
      let items = [];
      if ({} === this.user) return items;
      if (this.$hasRight("globalActions.showProjectsBtn"))
        items.push({
          title: "En cours",
          icon: "mdi-archive-star-outline",
          to: {
            name: "workspaces",
          },
        });
      if (this.$hasRight("globalActions.showArchivedProjectsBtn"))
        items.push({
          title: "Archivés",
          icon: "mdi-archive-check-outline",
          to: {
            name: "workspaces",
            params: {
              archived: "archived",
            },
          },
        });
      return items;
    },
  },
  async mounted() {
    this.showFolderMenu(this.$route);
    this.getUserOrganization();
    let user = {};
    if (auth.isLogged()) {
      this.user = auth.decodeToken();
      user = await this.$api.users.findById(this.user.userId);
      const path = user.profilePicturePath;

      if (null !== path && undefined !== path) {
        const doc = await this.$api.documents.download(path);
        this.profilePictureLink = window.URL.createObjectURL(doc);
      }
    } else if (auth.isLoggedGuest()) {
      user = await this.$api.guests.getByToken(auth.getGuestToken());
      this.user = {
        ...user,
        firstname: this.user.displayName,
        userId: this.user.id,
        job: "",
      };

      window.location.href =
        "/guests/" +
        user.campaign.type +
        "-campaign/" +
        user.campaign.id +
        "/dashboard";
    }

    this.$store.commit("user/setUser", this.user);
  },
  methods: {
    showModal() {
      this.$refs.modal.showModal();
    },
    async getUserOrganization() {
      if (undefined === this.$store.state.user.current.id)
        return setTimeout(this.getUserOrganization, 300);

      const userId = this.$store.state.user.current.invitedBy.id;
      if (undefined === userId)
        return setTimeout(this.getUserOrganization, 500);
      this.userOrganization =
        await this.$api.organizations.findUserOrganizations(userId);
    },
    logout() {
      auth.logout();
      this.$router.push({ name: "login" });
    },
    accountView() {
      this.$router.push({ name: "profile" });
    },
    async showFolderMenu(route) {
      if (this.displayFolderMenuRoutes.includes(route.name) === true) {
        const documentStats = await this.$api.campaigns.getDocumentsStats(
          route.params.id
        );

        this.$store.commit(
          "filesNotifications/updateFilesCount",
          documentStats.pendingDocuments
        );
        this.$store.commit(
          "filesNotifications/updateLivreriesCount",
          documentStats.last2DaysDeliverables
        );
        this.displayFolderMenu = true;
      } else {
        this.displayFolderMenu = false;
      }
    },
  },
  watch: {
    $route(to) {
      this.showFolderMenu(to);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

.v-navigation-drawer--mini-variant > div > button {
  right: 10px !important;
}

.m_navigation {
  background-color: $dark !important;
  color: #fff !important;
  position: fixed !important;

  .v-icon {
    width: 37px;
    margin-right: 16px;
  }

  .m_retract_icon {
    float: right;
    top: 5px;
    margin-right: 0;
  }
  .m_expand_icon {
    top: 5px;
  }

  .m_icon {
    color: #ed7507 !important;
    margin-right: 10px;

    i {
      font-size: 40px !important;
    }
  }

  .m_primary_links {
    font-family: "Open Sans", sans-serif;

    .m_primary_icon {
      background-color: transparent;
      color: white !important;
      border-radius: 50px;
      height: 37px;
      width: 37px;
    }

    div {
      font-size: 15px !important;
      font-weight: 300 !important;
      line-height: 2rem !important;
    }
  }

  .v-list--dense .v-subheader {
    font-size: 15px;
  }

  .v-list--dense .v-list-item .v-list-item__title {
    font-size: 15px;
    font-weight: normal !important;
  }

  .v-list-item {
    &__icon {
      margin-right: 0 !important;
    }
  }

  .v-list {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.v-item-group {
  background-color: inherit;
}

.m_profile {
  color: #fff !important;
  background-color: #412420 !important;

  .m_grey {
    color: $grey !important;
  }
}

.logo {
  width: 100%;
  margin-top: -20px;
}

.mdi-chevron-right,
.mdi-chevron-left {
  margin-right: 0 !important;
}

.force-bottom {
  position: absolute !important;
  bottom: 80px !important;
  width: 100% !important;
  font-size: 10px !important;
}

.logo-div {
  height: 40px;
  width: 100%;
  text-align: center;
  .v-icon {
    margin-right: 0px;
  }
}

.icon-height {
  height: 40px;
}
.no-height {
  height: 0;
}
.ml-2-5 {
  margin-left: 10px;
}

.active-class-list {
  background-color: white;
  margin-right: 20px;
  border-radius: 0 4px 4px 0;
}

.active-class-list-mini {
  background-color: white;
  margin-right: 5px;
  border-radius: 0 4px 4px 0;
}

.active-class-list > div.v-list-item__icon > i.v-icon,
.active-class-list-mini > div.v-list-item__icon > i.v-icon {
  color: #412420 !important;
}

.active-class-list > div.v-list-item__content > div.v-list-item__title,
.active-class-list-mini > div.v-list-item__content > div.v-list-item__title {
  color: #412420 !important;
}

.active-class-list > div.v-list-item__content > div.v-list-item__title > span {
  background-color: #412420;
  color: white;
}

.v-main {
  height: 100vh;
}

.m_textarea textarea {
  margin-bottom: 5px;
  margin-right: 7px;
}

#connected {
  display: flex;
}
.scrollable_container {
  overflow-y: auto;
  height: inherit;
}
.v-application .primary--text {
  color: $brown !important;
  caret-color: $brown !important;
}
</style>
