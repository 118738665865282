<template>
  <v-app>
    <template>
      <Ops />
      <div id="app">
        <router-view />
      </div>
      <SystemSnackbar />
    </template>
  </v-app>
</template>

<script>
import auth from "./service/auth";
import keyboard from "./service/keyboard";
import Ops from "@/components/Ops.vue";

export default {
  components: {
    Ops,
  },
  methods: {
    checkAuth() {
      if (null === this.$route.name) return setTimeout(this.checkAuth, 200);
     if (
        auth.isLogged() === false &&
        auth.isLoggedGuest() === false &&
        this.$route.name !== "login" &&
        this.$route.name !== "download-zip" &&
        this.$route.name !== "share-link-auth"
      ) {
        this.$router.push({ name: "login" });
      }
    },
  },
  mounted() {
    this.checkAuth()
    
    keyboard.listenToEscape(() => {
      this.$root.$emit("closeCurrent");
    });
  },
};
</script>

<style lang="scss">
@import "./assets/scss/_variables.scss";

.m_brown {
  color: $brown !important;
}
html {
  overflow-y: hidden !important;
}
</style>
