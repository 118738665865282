import Vue from "vue";

import agencies from "./organization/agencies";
import organizations from "./organization/organizations";

import users from "./users";
import guests from "./guests";

import workspaces from "./workspaces";

import campaigns from "./campaigns";

import auscultationCampaigns from "./auscultation/auscultationCampaigns";
import mapLayers from "./auscultation/mapLayers";

import coringCampaigns from "./coring/coringCampaigns";
import cores from "./coring/cores";
import coreLayers from "./coring/coreLayers";
import corePictures from "./coring/corePictures";
import coreInterfaces from "./coring/coreInterfaces";
import roads from "./coring/roads";
import materialCategories from "./coring/materialCategories";
import prestations from "./coring/prestations";
import materials from "./coring/materials";
import providers from "./coring/providers";
import images from "./auscultation/images";
import authentication from "./authentication";
import documents from "./documents";

const api = {
  authentication,

  users,
  guests,
  agencies,
  organizations,

  workspaces,
  campaigns,

  auscultationCampaigns,
  mapLayers,

  coringCampaigns,
  cores,
  coreInterfaces,
  coreLayers,
  corePictures,
  roads,
  materialCategories,
  materials,
  prestations,
  providers,
  images,
  documents,
};

export default {
  install() {
    Vue.prototype.$api = api;
  },
};
