import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
// const getMessage = toastPlugin.getMessage;

const logIn = async (username, password) => {
  try {
    const response = await axios.post(`/login_check`, { username, password });

    return response.data;
  } catch (error) {
    toast("error", "Email ou Mot de passe incorrect(s)");
    throw error;
  }
};

export default { logIn };
