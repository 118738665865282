import Vue from "vue";
import Vuex from "vuex";
import campaigns from "./modules/campaigns";
import filesNotifications from "./modules/filesNotifications";
import user from "./modules/user";
import campaign from "./modules/campaign";
import loader from "./modules/loader";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    user,
    campaigns,
    filesNotifications,
    campaign,
    loader,
  },
  strict: debug,
});
