import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findAll = async (customerOnly = false) => {
  try {
    const response = await axios.get("/agencies?customerOnly=" + customerOnly);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les agences" + getMessage(error.response.status)
    );
    throw error;
  }
};
const findById = async (id) => {
  try {
    const response = await axios.get(`/agencies/${id}`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer l'agence" + getMessage(error.response.status)
    );
    throw error;
  }
};
const findByOrganization = async (organizationId) => {
  try {
    const response = await axios.get(
      `/agencies?organizationId=${organizationId}`
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les agences" + getMessage(error.response.status)
    );
    throw error;
  }
};
const create = async (agency) => {
  try {
    const response = await axios.post("/agencies", agency);

    toast("success", "Agence créée !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de créer l'agence" + getMessage(error.response.status)
    );
    throw error;
  }
};
const edit = async (id, agency) => {
  try {
    const response = await axios.put(`/agencies/${id}`, agency);

    toast("success", "Agence modifiée !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier l'agence" + getMessage(error.response.status)
    );
    throw error;
  }
};
// delete is a reserved word
const remove = async (id, fallbackAgencyId) => {
  try {
    await axios.delete(`/agencies/${id}`, { data: { fallbackAgencyId } });
  } catch (error) {
    if (400 != status)
      this.$toast(
        "error",
        "Suppression impossible" + this.$getMessage(error.response.status)
      );
    else
      this.$toast(
        "error",
        "Vous devez sélectionner une agence de repli pour les projets et les utilisateurs de l'agence supprimée"
      );
    throw error;
  }
};

const getAgencyProviders = async (id) => {
  try {
    const response = await axios.get(
      `/agencies/${id}/providers`
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les prestataires" + getMessage(error.response.status)
    );
    throw error;
  }
}

const addProviderToAgency = async (agencyId, providerId, data) => {
  try {
    const response = await axios.post(
      `/agencies/${agencyId}/providers/${providerId}`,
      data
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de lier l'agence au prestataire" + getMessage(error.response.status)
    );
    throw error;
  }
}

const removeProviderFromAgency = async (agencyId, providerId) => {
  try {
    const response = await axios.delete(
      `/agencies/${agencyId}/providers/${providerId}`
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de supprimer la liaison" + getMessage(error.response.status)
    );
    throw error;
  }
}

const editAgencyProvider = async (agencyId, providerId, data) => {
  try {
    const response = await axios.put(
      `/agencies/${agencyId}/providers/${providerId}`,
      data
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les agences" + getMessage(error.response.status)
    );
    throw error;
  }
}

export default {
  findAll,
  findById,
  findByOrganization,
  create,
  edit,
  delete: remove,

  // providers
  getAgencyProviders,
  addProviderToAgency,
  editAgencyProvider,
  removeProviderFromAgency
};
