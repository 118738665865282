<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Support</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container>
          <div>
            <p>
              Vous avez une idée, une suggestion, une remontée de bug à nous
              faire parvenir ? Envoyez un mail à l'adresse suivante :
              <a target="_blank" href="mailto:support@maorie.com"
                >support@maorie.com</a
              >.
            </p>
            <p>Nous vous recontacterons très rapidement !</p>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center pb-4">
        <HighButtonSlot @click="hideModal"> Ok </HighButtonSlot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SupportModal",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
    },
  },
  mounted() {
    this.$root.$on("closeCurrent", this.hideModal);
  },
};
</script>

<style scoped></style>
