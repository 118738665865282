<template>
  <v-btn
    color="#2c0703"
    class="HighButtonSlot"
    @click="clicked($event)"
    :to="_to"
    :disabled="_disabled"
    :type="_type"
    :loading="_loading"
    :small="_small"
    elevation="0"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "HighButtonSlot",
  props: ["_to", "_disabled", "_type", "_loading", "_small"],
  methods: {
    clicked($event) {
      this.$emit("click", $event);
    }
  }
};
</script>

<style lang="scss">
.HighButtonSlot {
  .v-btn {
    &__content {
      color: #fff;
    }
    &--disabled {
      .v-btn__content {
        color: #000 !important;
      }
    }
  }
}
</style>
