import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const edit = async (interfaceId, interfacePayload) => {
  try {
    const response = await axios.put(
      `/cores-interfaces/${interfaceId}`,
      interfacePayload
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier l'interface" + getMessage(error.response.status)
    );
    throw error;
  }
};

const create = async (interfacePayload) => {
  try {
    const response = await axios.post("/cores-interfaces", interfacePayload);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de créer l'interface" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  edit,
  create,
};
