import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByWorkspace = async (workspaceId) => {
  try {
    const response = await axios.get(`/workspaces/${workspaceId}/roads`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les routes" + getMessage(error.response.status)
    );
    throw error;
  }
};

const create = async (road) => {
  try {
    const response = await axios.post(`/roads`, road);

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de créer la route" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  findByWorkspace,
  create,
};
