// initial state
const state = () => ({
  current: {}
});

// getters
const getters = {};

// mutations
const mutations = {
  setUser(state, user) {
    state.current = user;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
