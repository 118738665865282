import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findById = async (id) => {
  try {
    const response = await axios.get(`/coring-campaigns/${id}`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer la campagne" + getMessage(error.response.status)
    );
    throw error;
  }
};

const create = async (campaign) => {
  try {
    const response = await axios.post("/coring-campaigns", campaign);

    toast("success", "Campagne créée !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de créer la campagne" + getMessage(error.response.status)
    );
    throw error;
  }
};

const edit = async (id, campaign) => {
  try {
    const response = await axios.put(`/coring-campaigns/${id}`, campaign);

    toast("success", "Campagne modifiée !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier la campagne" + getMessage(error.response.status)
    );
    throw error;
  }
};

const editStatus = async (id, status) => {
  try {
    const response = await axios.patch(`/coring-campaigns/${id}`, { status });

    toast("success", "Statut modifié !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de modifier le statut" + getMessage(error.response.status)
    );
    throw error;
  }
};

const sendToProvider = async (id) => {
  try {
    const response = await axios.post(
      `/coring-campaigns/${id}/actions/send-to-provider`,
      { showLoader: false }
    );

    toast("success", "Campagne envoyée au prestataire !");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible d'envoyer les informations au prestataire" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const getFromProvider = async (id) => {
  try {
    const response = await axios.post(
      `/coring-campaigns/${id}/actions/get-from-provider`,
      { showLoader: false }
    );

    toast("success", "Informations récupérées");

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les informations de la campagne" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const getTemporaryPDF = async (campaignId) => {
  try {
    const response = await axios.get(`/coring-campaigns/${campaignId}/pdf`, {
      responseType: "arraybuffer",
    });

    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer le pdf" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  findById,
  create,
  edit,
  editStatus,
  sendToProvider,
  getFromProvider,
  getTemporaryPDF,
};
