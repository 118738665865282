import jwt_decode from "jwt-decode";

const auth = {
  login(token, refreshToken) {
    localStorage.setItem("token", token);
    localStorage.setItem("refresh_token", refreshToken);
  },

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
  },

  isLogged() {
    if (localStorage.getItem("token") === null) {
      return false;
    }
    return true;
  },
  isLoggedGuest() {
    return null !== this.getGuestToken()
  },
  loginGuest(token) {
    localStorage.setItem("guest_token", token);
  },
  getGuestToken() {
    return localStorage.getItem("guest_token");
  },

  decodeToken() {
    if (this.isLogged() === false) {
      return null;
    }
    const token = localStorage.getItem("token");
    return jwt_decode(token);
  },

  getJwt() {
    return localStorage.getItem("token");
  },

  getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
};

export default auth;
